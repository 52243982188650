<template>
	<div class="about">
		<v-container class="pa-0" fluid>

			<v-row align="center" justify="center">
				<v-col sm="12" md="12" lg="12" xl="12" class="pt-0 pb-0">
					<v-card
							color="black"
							dark
							tile
					>
						<v-card-title
								class="text-center justify-center">店舗一覧
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
<!---->
      <v-row align="center" justify="center">
        <v-col sm="12" md="10" lg="8" xl="8">
      <v-row align="baseline" justify="start">

        <v-col sm="12" md="4" lg="4" xl="4" v-for="shop in shops" :key="shop.no">

			<!--<v-row
					align="center"
					justify="center"
					v-for="shop in shops"
					:key="shop.no"
			>
				<v-col sm="12" md="10" lg="8" xl="8" class="pt-0 pb-0">-->
					<v-img
							contain
							:src="shop.image"
							width="100%"
					/>
				<v-card
						outlined
						tile
						class="border-none shop-card">
					<!--<v-row align="center" justify="center">
						<v-col sm="12" md="10" lg="8" xl="8" class="pb-0">-->
							<v-card-subtitle class="font-weight-bold">
								<v-chip
										class="mr-2 mb-2"
										color="black"
										outlined
										label
										small
										disabled

								>
									エリア
								</v-chip>
								{{shop.shop_area}}
								<br>
								<v-chip
										class="mr-2 mb-2"
										color="black"
										outlined
										label
										small
										disabled
								>
									ジャンル
								</v-chip>
								{{shop.shop_genre}}
							</v-card-subtitle>
							<div class="shop-title">
								<v-card-title class="font-weight-bold">
									{{shop.name}}
								</v-card-title>

								<v-card-subtitle>
									{{shop.furigana}}
								</v-card-subtitle>

								<v-card-subtitle class="abrid-add">
									〒{{shop.zip}} {{shop.city}}{{shop.address1}}{{shop.address2}}<br>
									{{shop.shop_address_note}}
								</v-card-subtitle>
								<v-btn
										tile
										depressed
										color="white"
										class="icon-map"
								>
									<a :href="shop.shop_googlemap" target="_blank">
										<i class="fas fa-map-marker-alt mr-1"></i>Google Map
									</a>
								</v-btn>
							</div>
							<v-card-subtitle>
								<div v-if="shop.shop_lunch_price">
									<span style="margin-right:1em;">ランチ</span>
									<span
											class="ml-2 title">&yen;{{shop.shop_lunch_price}}〜</span>(税サ別 / 人)<br>
								</div>
								<div v-if="shop.shop_dinner_price">
									ディナー
									<span class="ml-2 title">&yen;{{shop.shop_dinner_price}}〜</span>(税サ別 / 人)
								</div>
							</v-card-subtitle>
							<!--<v-divider></v-divider>-->
							<v-card-text class="pb-0 abrid-text">
								{{shop.shop_explanation}}
							</v-card-text>
						<!--</v-col>
					</v-row>-->

					<v-card-text class="btn-reserve">
						<!--<v-row align="center" justify="center" class="btn-reserve">
							<v-col sm="12" md="10" lg="8" xl="8">-->
								<v-btn
										color="#e60044"
										dark
										depressed
										tile
										block
										:to="`./shop/${shop.no}`"
								>
									お店を予約
								</v-btn>

							<!--</v-col>
						</v-row>-->
					</v-card-text>
				</v-card>
				</v-col>
			</v-row>
        </v-col>
      </v-row>

<!---->




		</v-container>
	</div>
</template>

<script>
	import shops from "@/api/shops.js";

	export default {
		props:{
			category: String,
		},
		data() {
			return {
				shops: null,
				shopsQuery:{
          memberType:1,
				},
			}
		},
		methods: {
			async setInfo(info) {
				this.shops = info.data;
				console.log(this.shops);
			}
		},
		mounted() {
			if(this.category != 'all') {
				this.shopsQuery.shop_genre = this.category
			}
			shops.fetchList(this.setInfo,'',this.shopsQuery);
		}
	}
</script>
<style scoped>
	@import "~@/styles/style.css";


	.v-text-field > .v-input__control > .v-input__slot::before{
		border-color: rgba(0, 0, 0, 0);
	}
	.btn-reserve button{
		width:100%;
		padding:32px;
	}
	.border-none {
		border:none!Important;
	}
	.card-pa0 {
		padding:0!important;
	}
	.icon-map i {
		color:#e60044;
		font-size:24px;
	}
	.v-text-field--filled {
		border-radius:0;
	}
	.select-menu {
		border-radius:0!important;
		height: 56px;
		overflow: hidden;
	}
	.btn-reserve button{
		height: auto!important;
		padding-top: 24px!important;
		padding-bottom: 24px!Important;
		font-weight:bold;
	}
	.btn-register{
		text-align:center;
	}
	.theme--light.v-card > .v-card__text, .theme--light.v-card .v-card__subtitle {
		color: rgba(0, 0, 0, 0.87);
	}
  .abrid-add {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    height: 6em;
  }
  .abrid-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
    height: 8em;
  }
</style>
